// import store from '@/store'
import axios from 'axios';
const md5 = require('./md5.js')

axios.defaults.timeout = 10000;  // 10s 自动超时

const createSign = (params) =>{
    // sort
    var params_list = [];
    for (var _key in params){
        if (params[_key] !== null && params[_key] !== undefined && params[_key] !== '' ){
            params_list.push({
                "key": _key,
                "value": typeof(params[ _key ])==='string'? params[ _key ]: JSON.stringify( params[ _key ] )   ,
            })
        }
    }
    // console.log(params_list);
    params_list.sort( function(a, b) {
        // return a.key > b.key ;
        return a.key.localeCompare(b.key);
    })
    // join params
    const secret_key = "25d55ad283aa400af464c76d713c07ad";

    var params_str_list = [];
    params_list.forEach((item, _index) => {
    //   console.log(item)
        params_str_list.push( item.key + "=" + item.value );
    })
    params_str_list.push("key=" + secret_key);
    var stringSignTemp = params_str_list.join('&');
    // md5 
    // console.log(stringSignTemp)
    const sign = md5(stringSignTemp).toUpperCase();
    // upper
    return sign
}

class HttpRequest {
    constructor() {
    }
    getInsideConfig() {
        const config = {
            headers: {
                // token: store.state.user.token,
                // menu: store.state.app.currentRouter ? store.state.app.currentRouter.name : ''
            }
        }
        return config
    }

    interceptors(instance, url) {
        // 请求拦截
        instance.interceptors.request.use(config => {
            // // 每次发送请求之前判断vuex中是否存在token 
            // // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
            // // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
            // const token = store.state.token; 
            // token && (config.headers.Authorization = token);    
            const token = sessionStorage.getItem('token');
            if (token ) { // 判断是否存在token，如果存在的话，则每个http header都加上token
                config.headers.authorization = token  //请求头加上token
            }
            // console.log(config)
            // 加密参数
            if (config.method === 'get'){
                // get请求
                config.params = config.params || {};
                var params = config.params ;
            }else{
                config.data  = config.data  || {};
                var params = config.data ;
            }
            // 
            params['timestamp'] = Date.parse(new Date()) * 0.001;
            // console.log("params", params)
            params['sign'] = createSign(params)
            return config;
        }, error => {
            return Promise.reject(error);
        }
        )
        // 响应拦截
        instance.interceptors.response.use(res => {
            // const { data, status } = res
            // return { data, status }
            return res
        }, error => {
            if (error.response && error.response.status) {
                switch (error.response.status) {
                    // 401: 未登录
                    // 未登录则跳转登录页面，并携带当前页面的路径
                    // 在登录成功后返回当前页面，这一步需要在登录页操作。 
                    case 401:
                        // router.replace({ 
                        // path: '/login', 
                        // query: { 
                        // redirect: router.currentRoute.fullPath 
                        // }
                        // });
                        break;
                    // 403 token过期
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面
                    case 403:
                        // Toast({
                        // message: '登录过期，请重新登录',
                        // duration: 1000,
                        // forbidClick: true
                        // });
                        // 清除token
                        localStorage.removeItem('token');
                        // store.commit('loginSuccess', null);
                        // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
                        setTimeout(() => {
                            router.replace({
                                path: '/login',
                                query: {
                                    redirect: router.currentRoute.fullPath
                                }
                            });
                        }, 1000);
                        break;

                    // 404请求不存在
                    case 404:
                        Toast({
                            message: '网络请求不存在',
                            duration: 1500,
                            forbidClick: true
                        });
                        break;
                    // 其他错误，直接抛出错误提示
                    default:
                        // Toast({
                        // message: error.response.data.message,
                        // duration: 1500,
                        // forbidClick: true
                        // });
                        break;
                }
                return Promise.reject(error);
            }else{
                return Promise.reject(error);
            }
        })
    }
    request(options) {
        const instance = axios.create();
        options = Object.assign(this.getInsideConfig(), options);
        this.interceptors(instance, options.url);
        return instance(options);
    }
}
const http = new HttpRequest()
export default http
